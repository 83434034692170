import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { InputModalComponent } from "./input-modal.component";
import { FormsModule } from "@angular/forms";

@NgModule({
  imports: [CommonModule, IonicModule, FormsModule],
  declarations: [InputModalComponent],
  exports: [InputModalComponent]
})
export class InputModalModule {
}
