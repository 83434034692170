import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class PinService {
  private pin: string;
  private storageKey = 'pin';
  private pinCheckedStorageKey = 'pinIsOK';

  constructor(private storageService: StorageService) {}

  public setPinIsOK(pinIsOK: string): void {
    sessionStorage.setItem(this.pinCheckedStorageKey, pinIsOK);
  }

  public getPinIsOk(): string {
    return sessionStorage.getItem(this.pinCheckedStorageKey);
  }

  public async getPin(): Promise<string> {
    return new Promise<string>(async (resolve) => {
      if (this.pin) {
        resolve(this.pin);
      } else {
        this.pin = await this.getPinFromStorage();
        resolve(this.pin);
      }
    });
  }

  public setPin(pin: string) {
    this.pin = pin;
    this.savePinInStorage(pin);
  }

  private async savePinInStorage(pin: string): Promise<void> {
    return await this.storageService.saveToStorage(this.storageKey, pin);
  }

  private async getPinFromStorage(): Promise<string> {
    return await this.storageService.getFromStorage(this.storageKey);
  }

  public async removePin(): Promise<void> {
    this.pin = undefined;
    return await this.storageService.removeFromStorage(this.storageKey);
  }
}
