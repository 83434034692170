import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { PAGES } from 'src/constants/pages';
import { PinService } from '../../services/pin/pin.service';

@Injectable({
  providedIn: 'root'
})
export class PinGuard implements CanActivate {
  constructor(private pinService: PinService, private router: Router) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      // Get pin
      const pin = await this.pinService.getPin();
      // Check if pin already is checked
      const pinIsOk = this.pinService.getPinIsOk();
      //  Check if pin is not null
      if (pin !== null) {
        if (pinIsOk) {
          // Allow access
          resolve(true);
        } else {
          this.router.navigateByUrl(PAGES.pinPage, {
            state: { title: 'pinMessages.pinControl', routedBySystem: true, pinControl: true }
          });
        }
      } else {
        this.router.navigateByUrl(PAGES.pinPage, {
          state: { title: 'pinMessages.firstTry', routedBySystem: true, newPin: true }
        });
      }
    });
  }
}
