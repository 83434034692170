import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from '../pipes/pipes.module';
import { HeaderComponent } from './header/header.component';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { ChecklistModalModule } from './checklist-modal/checklist-modal.module';
import { OrdersModalModule } from './orders-modal/orders-modal.module';
import { ChecklistSelectorModalModule } from './checklist-selector-modal/checklist-selector-modal.module';
import { MaterialsModalModule } from './materials-modal/materials-modal.module';

@NgModule({
  declarations: [DatePickerComponent, HeaderComponent],
  imports: [CommonModule, IonicModule, PipesModule, TranslateModule, ChecklistModalModule, OrdersModalModule, ChecklistSelectorModalModule, MaterialsModalModule],
  providers: [TranslatePipe],
  exports: [DatePickerComponent, HeaderComponent]
})
export class SharedModule {
}
