import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  constructor() {}

  /**
   * Returns the monday date of the given week
   * @param date Date of the week of the wanted monday
   */
  public getMondayOfTheWeek(date: Date): Date {
    // Make sure the date is a Date object
    date = new Date(date);
    // Get current day
    const day = date.getDay();
    // Get the monday day
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    // Return monday date
    return new Date(date.setDate(diff));
  }

  public getMinutesBetweenTwoDates(dateInPast: Date, dateInFuture: Date): number {
    // Make sure the date is a Date object
    dateInPast = new Date(dateInPast);
    dateInFuture = new Date(dateInFuture);
    const differenceInMilliseconds = dateInFuture.getTime() - dateInPast.getTime();
    const differenceInSeconds = differenceInMilliseconds / 1000;
    const differenceInMinutes = differenceInSeconds / 60;
    return Math.round(differenceInMinutes);
  }

  public addMinutesToDate(date: Date, minutes: number): Date {
    // Make sure the date is a Date object
    date = new Date(date);
    const newDate = new Date(date.getTime() + minutes * 60000);
    return newDate;
  }

  public removeMinutesFromDate(date: Date, minutes: number): Date {
    // Make sure the date is a Date object
    date = new Date(date);
    const newDate = new Date(date.getTime() - minutes * 60000);
    return newDate;
  }

  public getDateAndTimeAsString(date: Date): string {
    // Make sure the date is a Date object
    date = new Date(date);
    return this.getDateAsString(date) + ' ' + this.getTimeAsString(date);
  }

  public getDateAsString(date: Date): string {
    // Make sure the date is a Date object
    date = new Date(date);
    const day = date.getDate().toString();
    const month = (date.getMonth() + 1).toString();
    const year = date.getFullYear().toString();

    return `${day.length < 2 ? '0' + day : day}-${month.length < 2 ? '0' + month : month}-${year}`;
  }

  public getTimeAsString(date: Date, withSeconds = true): string {
    // Make sure the date is a Date object
    date = new Date(date);
    const hours = date.getHours().toString();
    const minutes = date.getMinutes().toString();
    if (withSeconds) {
      const seconds = date.getSeconds().toString();
      return `${hours.length < 2 ? '0' + hours : hours}:${minutes.length < 2 ? '0' + minutes : minutes}:${
        seconds.length < 2 ? '0' + seconds : seconds
      }`;
    } else {
      return `${hours.length < 2 ? '0' + hours : hours}:${minutes.length < 2 ? '0' + minutes : minutes}`;
    }
  }

  public roundToNearestQuarter(time: Date): Date {
    const timeToReturn = new Date(time);
    timeToReturn.setMilliseconds(Math.round(timeToReturn.getMilliseconds() / 1000) * 1000);
    timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
    timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 15) * 15);
    return timeToReturn;
  }

  public mergeTimeIntoDate(date: Date, time: Date): Date {
    date = new Date(date);
    time = new Date(time);
    const datetime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), time.getSeconds());
    return datetime;
  }
}
