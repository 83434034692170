import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  private log = console.log;
  private debug = environment.debug;

  constructor() {}

  public primary(tag: string, message: any): void {
    if (this.debug) {
      this.log(`%c # ${tag} \n ${message} `, 'background: #cce5ff; color: #004085');
    }
  }

  public secondary(tag: string, message: any): void {
    if (this.debug) {
      this.log(`%c # ${tag} \n ${message} `, 'background: #e2e3e5; color: #383d41');
    }
  }

  public success(tag: string, message: any): void {
    if (this.debug) {
      this.log(`%c # ${tag} \n ${message} `, 'background: #d4edda; color: #155724');
    }
  }

  public error(tag: string, message: any): void {
    if (this.debug) {
      this.log(`%c # ${tag} \n ${message} `, 'background: #f8d7da; color: #721c24');
    }
  }

  public warning(tag: string, message: any): void {
    if (this.debug) {
      this.log(`%c # ${tag} \n ${message} `, 'background: #fff3cd; color: #856404');
    }
  }

  public info(tag: string, message: any): void {
    if (this.debug) {
      this.log(`%c # ${tag} \n ${message} `, 'background: #d1ecf1; color: #0c5460');
    }
  }

  public light(tag: string, message: any): void {
    if (this.debug) {
      this.log(`%c # ${tag} \n ${message} `, 'background: #fefefe; color: #818182');
    }
  }

  public dark(tag: string, message: any): void {
    if (this.debug) {
      this.log(`%c # ${tag} \n ${message} `, 'background: #d6d8d9; color: #1b1e21');
    }
  }

  public object(object: any): void {
    if (this.debug) {
      this.log(object);
    }
  }

  public table(object: any): void {
    if (this.debug) {
      console.table(object);
    }
  }
}
