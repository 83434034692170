import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PAGES } from 'src/constants/pages';
import { AuthService } from './core/services/auth/auth.service';
import { SqlService } from './core/services/sql/sql.service';
import { App } from '@capacitor/app';
import { Location } from '@angular/common';
import { PinService } from './core/services/pin/pin.service';
import { STORAGE_KEYS } from 'src/constants/storage-keys';
import { UserService } from './core/services/user/user.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { User } from './core/models/user';
import { UserRole } from './core/enums/user-role.enum';
import { StorageService } from './core/services/storage/storage.service';
import { Device } from '@capacitor/device';
import * as Sentry from '@sentry/capacitor';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = -1;

  public isNative: boolean;

  public showMenuOnThesePages = [PAGES.assignmentsPage, PAGES.projectsPage, PAGES.activitiesPage, PAGES.settingsPage];

  public user: User;
  public userRole = UserRole;

  private defaultLanguage: string;

  private pages = PAGES;

  private hideSplashDelay = 1000;

  constructor(
    private translate: TranslateService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private platform: Platform,
    private sqlService: SqlService,
    private location: Location,
    private ref: ChangeDetectorRef,
    private storageService: StorageService
  ) {
  }

  ngOnInit(): void {
    // Check for native device
    this.isNative = this.platform.is('cordova');

    this.platform.ready().then(async () => {
      const deviceLanguage = (await Device.getLanguageCode()).value;
      // Get system language
      this.defaultLanguage = deviceLanguage === 'nl' ? 'nl' : 'en';
      // Get user
      this.user = await this.userService.getLocalUser();
      // Init app
      this.initApp();
      // Add little timeout to prevent white screen
      setTimeout(() => SplashScreen.hide(), this.hideSplashDelay);
    });
  }

  public async menuWillOpen(): Promise<void> {
    this.user = await this.userService.getLocalUser();
    this.ref.detectChanges();
  }

  public async initApp(): Promise<void> {
    if (this.platform.is('cordova')) {
      await this.sqlService.init();
      if (this.platform.is('android')) {
        this.closeAppOnRootPageBackButtonPress();
      }
    }

    const languageFromStorage = await this.storageService.getFromStorage(STORAGE_KEYS.language);

    // Set storage language or default when missing
    this.translate.setDefaultLang(languageFromStorage ? languageFromStorage : this.defaultLanguage);
    await this.storageService.saveToStorage(STORAGE_KEYS.language, this.defaultLanguage);

    await this.authService.getAccessTokenFromStorage();
  }

  /**
   * Returns true when last part of current path is equal to one of the strings from the array
   * @param showMenuOnThesePages The string array to compare with the current path
   */
  public showMenuBars(showMenuOnThesePages: string[]): boolean {
    // Get last part of current path
    const path = window.location.pathname.split('/')[1];
    // Compare path to array elements
    for (let i = 0; i < showMenuOnThesePages.length; i++) {
      if (this.showMenuOnThesePages[i] === path) {
        return true;
      }
    }
    return false;
  }

  /**
   * Sets the selected class for the given index and undo it after 250 ms
   * @param itemIndex Index to set
   */
  public onMenuItemClick(itemIndex: number): void {
    this.selectedIndex = itemIndex;
    setTimeout(() => {
      this.selectedIndex = -1;
    }, 250);
  }

  public toProjectsPage() {
    this.onMenuItemClick(0);
    this.router.navigateByUrl(`${this.pages.projectsPage}`);
  }

  public toActivitiesPage() {
    this.onMenuItemClick(1);
    this.router.navigateByUrl(`${this.pages.activitiesPage}`);
  }

  public toAssignmentsPage() {
    this.onMenuItemClick(2);
    this.router.navigateByUrl(`${this.pages.assignmentsPage}`);
  }

  public toSettingsPage(): void {
    this.onMenuItemClick(3);
    this.router.navigateByUrl(`/${PAGES.settingsPage}`);
  }

  /**
   * Removes token and redirect to login page.
   */
  public toLoginPage(): void {
    // Remove token
    this.authService.removeAccessToken();
    // Remove user
    this.userService.removeUserLocally();
    // Remove sqLite data on native device
    if (this.isNative) {
      this.sqlService.deleteAllData();
    }
    // Let other processes no the user logged out
    this.userService.userLoggedOut.emit();
    // Let login page know user logged out
    sessionStorage.setItem(STORAGE_KEYS.isLoggedOutKeyName, 'true');
    this.onMenuItemClick(4);
    setTimeout(() => {
      this.router.navigate([this.pages.loginPage]);
    }, 100);
  }

  /**
   * Sets an event listener on the android back button and closes the app when pressed on the root page.
   */
  private closeAppOnRootPageBackButtonPress(): void {
    document.addEventListener('ionBackButton', (ev: any) => {
      ev.detail.register(-1, () => {
        const path = window.location.pathname;
        if (path === `/${PAGES.rootPage}` || path === `/${PAGES.loginPage}`) {
          App.exitApp();
        } else {
          this.location.back();
        }
      });
    });
  }
}
