import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { PAGES } from 'src/constants/pages';
import { AuthService } from '../../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // The url wanted to visit
    const url: string = state.url;
    return await this.checkLogin(url);
  }

  /**
   * Check if user is authenticated for specific url and redirect
   * @param url The wanted url
   */
  public async checkLogin(url: string): Promise<boolean> {
    // Get token
    let accessToken = this.authService.getAccessToken();
    // Check token
    if (accessToken) {
      // Go to wanted url
      return true;
    } else {
      // Try to get token from storage
      accessToken = await this.authService.getAccessTokenFromStorage();
      // Check token
      if (accessToken) {
        // Go to wanted url
        return true;
      } else {
        // Store the attempted URL for redirecting
        this.authService.redirectUrl = url;

        // Navigate to the login page.
        this.router.navigate([PAGES.loginPage]);
        return false;
      }
    }
  }
}
