import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TrimPipe } from 'src/app/pipes/trim/trim.pipe';
import { FilenameFromPathPipe } from './filenameFromPath/filename-from-path.pipe';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [TrimPipe, FilenameFromPathPipe],
  exports: [TrimPipe, FilenameFromPathPipe]
})
export class PipesModule {}
