import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserRole } from '../enums/user-role.enum';
import { UserService } from '../services/user/user.service';
import { PAGES } from '../../../constants/pages';

@Injectable({
  providedIn: 'root'
})
export class ExecutorGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const user = await this.userService.getLocalUser();

    if (user.role === UserRole.executor) {
      return true;
    } else {
      await this.router.navigateByUrl(PAGES.assignmentsPage);
    }
  }
}
