import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { STORAGE_KEYS } from 'src/constants/storage-keys';
import { environment } from 'src/environments/environment';
import { Option } from '../../enums/option.enum';
import { User } from '../../models/user';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public userLoggedOut = new EventEmitter<void>();

  private api: string;
  private storageKey = STORAGE_KEYS.user;

  constructor(private http: HttpClient, private storageService: StorageService) {
    this.api = environment.api;
  }

  public userHasOption(user: User, option: Option): boolean {
    if (user) {
      if (user.options) {
        return user.options.indexOf(option) > -1;
      } else {
        return false;
      }
    }
  }

  public getUser(): Observable<User> {
    return this.http.get<User>(`${this.api}/user`);
  }

  public getLocalUser(): Promise<User> {
    return new Promise<User>((resolve) => {
      this.storageService.getFromStorage(this.storageKey).then((user) => {
        resolve(JSON.parse(user));
      });
    });
  }

  public saveUserLocally(user: User): Promise<void> {
    const userInJson = JSON.stringify(user);
    return this.storageService.saveToStorage(this.storageKey, userInJson);
  }

  public removeUserLocally(): Promise<void> {
    return this.storageService.removeFromStorage(this.storageKey);
  }
}
