import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { ChecklistModalComponent } from './checklist-modal.component';
import { SlideControlBarComponent } from './components/slide-control-bar/slide-control-bar.component';
import { ChecklistQuestionComponent } from './components/checklist-question/checklist-question.component';
import { TranslateModule } from '@ngx-translate/core';
import { SignatureModule } from '../signature/signature.module';

@NgModule({
  imports: [CommonModule, IonicModule, FormsModule, TranslateModule, SignatureModule],
  declarations: [ChecklistModalComponent, SlideControlBarComponent, ChecklistQuestionComponent]
})
export class ChecklistModalModule {}
