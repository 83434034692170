export const PAGES = {
  rootPage: 'assignments',
  loginPage: 'login',
  assignmentsPage: 'assignments',
  pinPage: 'pin',
  passwordPage: 'password',
  projectsPage: 'projects',
  phasePage: 'phase',
  activitiesPage: 'activities',
  settingsPage: 'settings'
};
