import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { ChecklistSelectorModalComponent } from './checklist-selector-modal.component';

@NgModule({
  imports: [CommonModule, IonicModule, FormsModule],
  declarations: [ChecklistSelectorModalComponent]
})
export class ChecklistSelectorModalModule {
}
